import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import {
  Empty,
  Button,
  Spin,
  Row,
  Col,
  Divider,
  Checkbox,
  Input,
  Modal,
  Tooltip,
} from "antd";
import { format } from "date-fns";

import { doc, getDoc, updateDoc } from "firebase/firestore";

import { firebaseDb } from "../firebase";

import { InfoCircleOutlined } from "@ant-design/icons";

import { getDesc } from "../utils/getDesc";

function capitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

const SignAgreement = () => {
  const { id } = useParams();
  const [pageData, setPageData] = useState({
    creatorData: null,
    loading: false,
    error: false,
  });

  const [inputData, setInputData] = useState({
    name: "",
    checked: false,
  });

  useEffect(() => {
    const fetchCreatorData = async () => {
      setPageData({ ...pageData, loading: true });
      try {
        const docRef = doc(firebaseDb, "agreements", id);
        const docSnap = await getDoc(docRef);

        if (docSnap.exists()) {
          setPageData((pageData) => ({
            ...pageData,
            creatorData: docSnap.data(),
          }));
        } else {
          // doc.data() will be undefined in this case
          setPageData((pageData) => ({ ...pageData, error: true }));
          console.log("No such document!");
        }
      } catch (error) {
        console.log("Error getting document:", error);
        setPageData((pageData) => ({ ...pageData, error: true }));
      }
      setPageData((pageData) => ({ ...pageData, loading: false }));
    };
    fetchCreatorData();
  }, [id]);

  if (pageData.loading) {
    return (
      <div
        style={{
          padding: "20% 0",
          textAlign: "center",

          height: "100%",
          width: "100%",
        }}>
        <Spin size="large" />
      </div>
    );
  }

  if (pageData.error) {
    return (
      <Empty style={{ margin: "200px 0" }} description={"No such Agreement!"} />
    );
  }

  return (
    <>
      <Col
        span={window?.innerWidth > 1000 ? 12 : 20}
        offset={2}
        style={{
          margin: "100px auto",
        }}>
        <div
          style={{
            display: "grid",
            gap: "20px",
            height: "45vh",
            overflowY: "scroll",
          }}>
          {/* Term */}
          <div
            style={{
              border: "1px solid grey",
              padding: "20px",
              borderRadius: "4px",
            }}>
            <Row justify="space-between">
              <h2>Term</h2>
              <Tooltip
                placement="top"
                title={" Click for easy understanding"}
                arrow={{ pointAtCenter: true }}>
                <Button
                  onClick={() =>
                    Modal.info({
                      title: "Term",
                      content: getDesc("Term"),
                    })
                  }>
                  <InfoCircleOutlined />
                </Button>
              </Tooltip>
            </Row>

            <Divider style={{ borderWidth: "5px", borderColor: "black" }} />
            <div>
              This agreement shall commence on{" "}
              {pageData?.creatorData?.dateOfStart
                ? format(
                    new Date(pageData?.creatorData?.dateOfStart || ""),
                    "d LLL, yyyy"
                  )
                : ""}{" "}
              and shall continue for a period of three (3) months (the "Initial
              Term"). This agreement may be renewed for an additional three (3)
              month term (the "Renewal Term") upon mutual agreement of both
              parties. The terms and conditions of the Renewal Term shall be
              shared by the parties prior to the expiration of the Initial Term
            </div>
          </div>

          {/* Obligations */}

          <div
            style={{
              border: "1px solid grey",
              padding: "20px",
              borderRadius: "4px",
            }}>
            <Row justify="space-between">
              <h2>RIGHTS AND OBLIGATIONS OF THE CREATOR</h2>
              <Tooltip
                placement="top"
                title={" Click for easy understanding"}
                arrow={{ pointAtCenter: true }}>
                <Button
                  onClick={() =>
                    Modal.info({
                      title: "RIGHTS AND OBLIGATIONS OF THE CREATOR",
                      content: getDesc("Rights"),
                    })
                  }>
                  <InfoCircleOutlined />
                </Button>
              </Tooltip>
            </Row>
            <Divider style={{ borderWidth: "5px", borderColor: "black" }} />
            <div>
              <ul>
                <li>
                  The Creator should always use the assigned email (shared by
                  the company with the ending of @x.cloutflow.com) by the
                  company on all their social handles for business and
                  promotional purposes.
                </li>
                <li>
                  All the commercial deals regarding the social media handles
                  (as mentioned in schedule-1) of the Creator shall be managed
                  by the company. In case the creator wishes to directly take up
                  any commercial deal in context, they need approval from the
                  company.
                </li>
              </ul>
            </div>
          </div>

          {/* Service Fee */}
          <div
            style={{
              border: "1px solid grey",
              padding: "20px",
              borderRadius: "4px",
            }}>
            <h2>Service Fee</h2>

            <Divider style={{ borderWidth: "5px", borderColor: "black" }} />
            <div>
              The company would charge a service fee (in terms of percentage)
              from all the commercial projects/campaigns they undertake (Service
              fee mentioned in Schedule-I).
            </div>
          </div>

          {/* Representations and Warranties */}
          <div
            style={{
              border: "1px solid grey",
              padding: "20px",
              borderRadius: "4px",
            }}>
            <Row justify="space-between">
              <h2>REPRESENTATIONS AND WARRANTIES</h2>
              <Tooltip
                placement="top"
                title={" Click for easy understanding"}
                arrow={{ pointAtCenter: true }}>
                <Button
                  onClick={() =>
                    Modal.info({
                      title: "REPRESENTATIONS AND WARRANTIES",
                      content: getDesc("Warranty"),
                    })
                  }>
                  <InfoCircleOutlined />
                </Button>
              </Tooltip>
            </Row>
            <Divider style={{ borderWidth: "5px", borderColor: "black" }} />
            <div>
              Parties represent and warrant to each other that each is free to
              enter into this Agreement and that this engagement does not
              violate the terms of any agreement between any third party.
              <ul>
                <li>
                  Each Party represents, warrants, and covenants to the other
                  Party that:
                  <ol>
                    <li>
                      It has full capacity and authority to enter, execute,
                      deliver, and perform this Agreement;
                    </li>
                    <li>
                      The execution of this Agreement and the performance of its
                      obligations under this Agreement and the implementation of
                      terms and conditions contemplated, hereby do not
                      constitute a breach of any contract, agreement,
                      arrangement, or understanding entered into by it with any
                      third party or any intellectual property rights of any
                      third party; and Further the Creator represents, warrants,
                      and covenants to the other Party that
                    </li>
                  </ol>
                </li>
                <li>
                  All fans and followers of the Creator on social media
                  platforms as defined under this Agreement are natural persons,
                  and The Creator has neither been involved in any unethical or
                  malpractice activity or action to increase its presence on
                  social media platforms nor has through any third party engaged
                  in the malpractice of increasing the number of fans or
                  followers on Social media platform. For the sake of clarity,
                  It is further represented by the creator that none of the fans
                  or followers of the Creator on any social media platform are
                  fake profiles created only for boosting their number of
                  followers in order to gain popularity
                </li>
                <li>
                  The creator shall abide by the rules of the relevant social
                  media platforms, ASCI Guidelines for Creators, and other
                  relevant statutory relevant guidelines issued by Governmental
                  authorities from time to time.
                </li>
              </ul>
              <p>
                Provided that on the occurrence of any curable event of default
                or material breach, the Company shall provide written notice of
                such material breach to the Creator, and the Creator shall
                within Seventy-Two (72) Hours (“Rectification Period”) from the
                receipt of the notice by the Company of such material breach,
                cure such breach or failure and shall provide to the Company,
                evidence of such cure.
              </p>
            </div>
          </div>

          {/* BReach */}
          <div
            style={{
              border: "1px solid grey",
              padding: "20px",
              borderRadius: "4px",
            }}>
            <Row justify="space-between">
              <h2>BREACH OF AGREEMENT</h2>
              <Tooltip
                placement="top"
                title={" Click for easy understanding"}
                arrow={{ pointAtCenter: true }}>
                <Button
                  onClick={() =>
                    Modal.info({
                      title: "BREACH OF AGREEMENT",
                      content: getDesc("Breach"),
                    })
                  }>
                  <InfoCircleOutlined />
                </Button>
              </Tooltip>
            </Row>
            <Divider style={{ borderWidth: "5px", borderColor: "black" }} />
            <div>
              The Parties agree that the occurrence of any of the following
              events shall be considered as Material Breach or Material Event of
              Default under this Agreement: (“Event of Default”)
              <ul>
                <li>
                  If the Creator fails to adhere to any Obligations imposed on
                  the Creator as per terms of “RIGHTS AND OBLIGATIONS OF THE
                  CREATOR” of this Agreement.
                </li>
                <li>
                  Breach of ”REPRESENTATIONS AND WARRANTIES” as specified under
                  this Agreement.
                </li>
                <li>
                  Any willful act or omission by the Creator during the tenure
                  of this Agreement, against the terms of this Agreement or
                  against the interest of the Company.
                </li>
                <li>
                  If the Creator fails to adhere to any Obligations imposed on
                  the Creator as per terms of “INDEMNIFICATION” of this
                  Agreement.
                </li>
              </ul>
              <p>
                Provided that on the occurrence of any curable event of default
                or material breach, the Company shall provide written notice of
                such material breach to the Creator, and the Creator shall
                within Seventy-Two (72) Hours (“Rectification Period”) from the
                receipt of the notice by the Company of such material breach,
                cure such breach or failure and shall provide to the Company,
                evidence of such cure.
              </p>
            </div>
          </div>

          {/* Indemnification */}
          <div
            style={{
              border: "1px solid grey",
              padding: "20px",
              borderRadius: "4px",
            }}>
            <Row justify="space-between">
              <h2>INDEMNIFICATION </h2>
              <Tooltip
                placement="top"
                title={" Click for easy understanding"}
                arrow={{ pointAtCenter: true }}>
                <Button
                  onClick={() =>
                    Modal.info({
                      title: "INDEMNIFICATION ",
                      content: getDesc("INDEMNIFICATION"),
                    })
                  }>
                  <InfoCircleOutlined />
                </Button>
              </Tooltip>
            </Row>
            <Divider style={{ borderWidth: "5px", borderColor: "black" }} />
            <div>
              The Creator shall indemnify and hold harmless the Company, its
              affiliates, agents, officers, directors, employees, and assigns
              (each an "Indemnified Party") from any losses, claims, actions,
              proceedings, demands, damages, costs, and expenses, including
              attorneys' and accountants' fees (collectively "Losses"), arising
              out of or related to: (i) any misleading or deceptive information
              provided by the Creator; (ii) the Creator's failure to comply with
              any obligation, covenant, or condition in this Agreement; (iii)
              the Creator's breach of any representation or warranty in this
              Agreement; (iv) any Material Adverse Effect or Event of Default or
              its cause. This indemnification obligation shall not limit any
              other rights the Company may have under this Agreement or
              applicable law.
            </div>
          </div>

          {/* Governing Law */}
          <div
            style={{
              border: "1px solid grey",
              padding: "20px",
              borderRadius: "4px",
            }}>
            <h2>GOVERNING LAW</h2>
            <Divider style={{ borderWidth: "5px", borderColor: "black" }} />
            <div>
              This Agreement will be governed by and construed in accordance
              with laws of India and the courts in New Delhi shall have
              exclusive jurisdiction in respect of this Agreement.
            </div>
          </div>

          {/* Schedule */}
          <div
            style={{
              border: "1px solid grey",
              padding: "20px",
              borderRadius: "4px",
            }}>
            <h2>Schedule 1</h2>
            <Divider style={{ borderWidth: "5px", borderColor: "black" }} />
            <div>
              <div style={{ display: "grid", gap: "10px" }}>
                <Row gutter={20} style={{ maxWidth: "300px" }}>
                  <Col span={12} style={{ fontWeight: "700" }}>
                    Name
                  </Col>
                  <Col span={12} style={{ fontWeight: "700" }}>
                    {pageData?.creatorData?.influencerName}
                  </Col>
                </Row>

                <Row gutter={20} style={{ maxWidth: "300px" }}>
                  <Col span={12} style={{ fontWeight: "700" }}>
                    Service Fee
                  </Col>
                  <Col span={12} style={{ fontWeight: "700" }}>
                    {pageData?.creatorData?.serviceFee} {"%"}
                  </Col>
                </Row>
                <Row gutter={20} style={{ maxWidth: "300px" }}>
                  <Col span={12} style={{ fontWeight: "700" }}>
                    Start Date
                  </Col>
                  <Col span={12} style={{ fontWeight: "700" }}>
                    {pageData?.creatorData?.dateOfStart
                      ? format(
                          new Date(pageData?.creatorData?.dateOfStart || ""),
                          "d LLL, yyyy"
                        )
                      : ""}
                  </Col>
                </Row>
                <Row gutter={20} style={{ maxWidth: "300px" }}>
                  <Col span={12} style={{ fontWeight: "700" }}>
                    Platforms
                  </Col>
                  <Col span={12} style={{ fontWeight: "700" }}>
                    <ul style={{ display: "grid", gap: "20px" }}>
                      {pageData?.creatorData?.socialMediaHandle?.map(
                        (item, i) => {
                          return (
                            <li key="i">
                              {capitalizeFirstLetter(item.platform)}:{" "}
                              <a
                                style={{
                                  overflowWrap: "break-word",
                                }}
                                href={item.link}
                                target="_blank"
                                rel="noreferrer">
                                <p
                                  style={{
                                    overflowWrap: "break-word",
                                    width:
                                      window?.innerWidth > 768
                                        ? "100%"
                                        : "100px",
                                    margin: 0,
                                  }}>
                                  {item.link}
                                </p>
                              </a>
                            </li>
                          );
                        }
                      )}
                    </ul>
                  </Col>
                </Row>
              </div>
            </div>
          </div>
        </div>

        <Col>
          <Checkbox
            checked={
              pageData?.creatorData?.isSubmitted ? true : inputData.checked
            }
            onChange={(e) =>
              setInputData((inputData) => ({
                ...inputData,
                checked: e.target.checked,
              }))
            }
            style={{ marginTop: "20px" }}
            disabled={pageData?.creatorData?.isSubmitted}>
            <h4> I hereby agree the terms and conditions</h4>
            <p
              style={{
                marginTop: "-15px",
                fontSize: "14px",
                lineHeight: "18px",
                color: "#686363",
              }}>
              By electronically signing the agreement below, your company is
              entering into legally binding agreement. Please download and read
              carefully prior to signing. Any Agreement, including the Terms and
              Conditions will be effective when signed by both parties. When
              signing this Agreement you must have the proper authority to
              execute this Agreements) on behalf of the company listed above and
              incur the obligations described in this Agreement(s) on behalf of
              such a company.
            </p>
          </Checkbox>
        </Col>
        <Col>
          <h4> Signature *</h4>
          <Input
            value={
              pageData?.creatorData?.isSubmitted
                ? pageData?.creatorData?.influencerName
                : inputData?.name
            }
            placeholder="Enter your Name"
            disabled={pageData?.creatorData?.isSubmitted}
            onChange={(e) =>
              setInputData((inputData) => ({
                ...inputData,
                name: e.target.value,
              }))
            }
          />
          <h6> Signing as ({pageData?.creatorData?.influencerName})</h6>
        </Col>
        <Row justify="center" style={{ marginTop: "20px" }}>
          <Button
            disabled={
              pageData?.creatorData?.isSubmitted ||
              !inputData?.checked ||
              !inputData?.name ||
              inputData?.name.trim() !==
                pageData?.creatorData?.influencerName.trim()
            }
            type="primary"
            size="large"
            style={{ minWidth: "100px" }}
            onClick={() => {
              const docRef = doc(firebaseDb, "agreements", id);
              updateDoc(docRef, {
                isSubmitted: true,
                signedOn: new Date().toISOString(),
              })
                .then(() => {
                  setPageData((pageData) => {
                    return {
                      ...pageData,
                      creatorData: {
                        ...pageData.creatorData,
                        isSubmitted: true,
                      },
                    };
                  });

                  Modal.success({
                    title: "Successfully Signed",
                    content: (
                      <>
                        <p>
                          Congratulations, you have officially sealed the deal!
                          We are thrilled to have you on board and cannot wait
                          to see your influence in action.
                        </p>
                        <p>
                          Our team is excited to work with you to create
                          exceptional content that will elevate your personal
                          brand and drive engagement to new heights. We believe
                          that together, we can make magic happen!
                        </p>
                        <p>
                          Thank you for choosing to partner with us. Let's make
                          some waves!
                          <br /> Best regards,
                          <br /> Cloutflow,
                        </p>
                      </>
                    ),
                  });
                })
                .catch(() =>
                  Modal.error({
                    title: "Something went wrong , Please try again later",
                  })
                );
            }}>
            Sign
          </Button>
        </Row>
      </Col>
      <img
        src="/logo.png"
        alt="logo"
        style={{
          width: "160px",
          height: "24px",
          position: "absolute",
          top: "20px",
          left: "20px",
        }}
      />
    </>
  );
};

export default SignAgreement;
