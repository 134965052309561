import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";

const firebaseConfig = {
  apiKey: "AIzaSyCO4vWUUETTkp1lUO0BuQz35wndSfG7cpQ",
  authDomain: "talent-agreement.firebaseapp.com",
  projectId: "talent-agreement",
  storageBucket: "talent-agreement.appspot.com",
  messagingSenderId: "154572201610",
  appId: "1:154572201610:web:4366d4181d8bbf70a01773",
  measurementId: "G-67GG0SHDXR",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const firebaseDb = getFirestore(app);
export const auth = getAuth(app);
export default app;
