import React, { useState } from "react";
import {
  Modal,
  Space,
  Input,
  DatePicker,
  InputNumber,
  Col,
  Button,
  Row,
} from "antd";

import dayjs from "dayjs";

import { collection, addDoc } from "firebase/firestore";

import { firebaseDb } from "../firebase";

const AddCreator = ({ visible, handleSubmit, handleClose }) => {
  const [inputData, setInputData] = useState({
    influencerName: "",
    address: "",
    phoneNumber: "",
    serviceFee: "",
    dateOfStart: "",
    socialMediaHandle: [],
    createdOn: new Date().toISOString(),
    signedOn: "",
    isSubmitted: false,
  });

  const resetInputData = () => {
    setInputData({
      influencerName: "",
      address: "",
      phoneNumber: "",
      serviceFee: "",
      dateOfStart: new Date().toISOString(),
      socialMediaHandle: [],
      createdOn: new Date().toISOString(),
      signedOn: "",
      isSubmitted: false,
    });
    setLoading(false);
  };

  const [loading, setLoading] = useState(false);

  return (
    <Modal
      open={visible}
      okButtonProps={{ loading }}
      width={700}
      onOk={async () => {
        setLoading(true);
        const docRef = await addDoc(
          collection(firebaseDb, "agreements"),
          inputData
        );
        console.log("Document written with ID: ", docRef.id);
        resetInputData();
        handleSubmit();
      }}
      onCancel={() => {
        resetInputData();
        handleClose();
      }}
      title="Add Creator Details">
      <Row gutter={[20, 20]}>
        <Col span={24}>
          <Space>
            Name
            <Input
              value={inputData.influencerName}
              onChange={(e) => {
                setInputData({
                  ...inputData,
                  influencerName: e.target.value || "",
                });
              }}
            />
          </Space>
        </Col>

        <Col span={24}>
          <Space>
            Phone Number
            <Input
              value={inputData.phoneNumber}
              onChange={(e) => {
                setInputData({
                  ...inputData,
                  phoneNumber: e.target.value || "",
                });
              }}
            />
          </Space>
        </Col>

        <Col span={24}>
          <Space>
            Address
            <Input
              value={inputData.address}
              onChange={(e) => {
                setInputData({
                  ...inputData,
                  address: e.target.value || "",
                });
              }}
            />
          </Space>
        </Col>

        <Col span={24}>
          <Space>
            {" "}
            Start Date
            <DatePicker
              value={dayjs(inputData.dateOfStart || new Date())}
              onChange={(value) =>
                setInputData((inputData) => ({
                  ...inputData,
                  dateOfStart: value?.toISOString() || "",
                }))
              }
            />
          </Space>
        </Col>
        <Col span={24}>
          <Space>
            {" "}
            Service Fee
            <InputNumber
              value={inputData.serviceFee}
              addonAfter={"%"}
              size="middle"
              onChange={(val) =>
                setInputData((inputData) => ({ ...inputData, serviceFee: val }))
              }
            />
          </Space>
        </Col>
        <Col span={24}>
          <Row>
            <Col span={7}>Social Media Handles</Col>
            <Col span={17}>
              {inputData?.socialMediaHandle.map((item, idx) => {
                return (
                  <Space size={4} key={idx} style={{ margin: "5px 0" }}>
                    <Input
                      placeholder="Enter Link"
                      value={item.link}
                      onChange={(e) =>
                        setInputData((inputData) => ({
                          ...inputData,
                          socialMediaHandle: inputData.socialMediaHandle.map(
                            (item, itemIdx) => {
                              if (itemIdx !== idx) {
                                return item;
                              }
                              return { ...item, link: e.target.value || "" };
                            }
                          ),
                        }))
                      }
                    />
                    <Input
                      placeholder="Enter Platform"
                      value={item.platform}
                      onChange={(e) =>
                        setInputData((inputData) => ({
                          ...inputData,
                          socialMediaHandle: inputData.socialMediaHandle.map(
                            (item, itemIdx) => {
                              if (itemIdx !== idx) {
                                return item;
                              }
                              return {
                                ...item,
                                platform: (
                                  e?.target?.value || ""
                                ).toLowerCase(),
                              };
                            }
                          ),
                        }))
                      }
                    />
                  </Space>
                );
              })}
              <Col>
                <Button
                  type="primary"
                  style={{ marginTop: "10px" }}
                  onClick={() =>
                    setInputData((inputData) => ({
                      ...inputData,
                      socialMediaHandle: [
                        ...inputData.socialMediaHandle,
                        { link: "", platform: "" },
                      ],
                    }))
                  }>
                  Add link
                </Button>
              </Col>
            </Col>
          </Row>
        </Col>
      </Row>
    </Modal>
  );
};

export default AddCreator;
