import logo from "./logo.svg";
import { Route, Routes } from "react-router-dom";
import "./App.css";

import Home from "./pages/Home";
import SignAgreement from "./pages/SignAgreement";

function App() {
  return (
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/:id" element={<SignAgreement />} />
      <Route path="*" element={<p>Nothing Found</p>} />
    </Routes>
  );
}

export default App;
