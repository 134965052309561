import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import {
  Modal,
  Input,
  Row,
  Col,
  Button,
  Divider,
  Tag,
  Spin,
  Empty,
  message,
} from "antd";

import {
  collection,
  query,
  getDocs,
  onSnapshot,
  orderBy,
  startAfter,
  limit,
  deleteDoc,
  doc,
} from "firebase/firestore";

import { firebaseDb } from "../firebase";

import AddCreator from "../components/AddCreator";

import InfiniteScroll from "react-infinite-scroller";

import { format } from "date-fns";

const Home = () => {
  const [modalProps, setModalProps] = useState({ visible: true, input: "" });
  const [addCreatorVisible, setAddCreatorVisible] = useState(false);

  const [creatorData, setCreatorData] = useState([]);
  const [docSnapshot, setDocSnapshot] = useState([]);

  const navigate = useNavigate();

  const fetchCreatorData = async () => {
    let arr = [...creatorData];
    const firebaseQuery = query(
      collection(firebaseDb, "agreements"),
      orderBy("createdOn", "desc"),
      limit(10)
    );

    const dataListener = onSnapshot(firebaseQuery, (snapshot) => {
      setDocSnapshot(snapshot.docs);
      snapshot.docChanges().forEach((change) => {
        if (change.type === "added") {
          if (arr.findIndex((not) => not.id === change.doc.id) === -1) {
            arr.push({
              ...change.doc.data(),
              id: change.doc.id,
            });
          }
        }
        if (change.type === "modified") {
          const newArray = arr.map((item) => {
            if (item.id === change.doc.id) {
              return {
                ...change.doc.data(),
                id: change.doc.id,
              };
            }
            return item;
          });
          arr = newArray;
        }
        if (change.type === "removed") {
          const newArray = arr.filter((item) => item.id !== change.doc.id);
          arr = newArray;
        }
      });

      setCreatorData(arr);
    });
    return dataListener;
  };

  const loadMore = async () => {
    if (docSnapshot[docSnapshot.length - 1] !== undefined) {
      const next = query(
        collection(firebaseDb, "agreements"),
        orderBy("createdOn", "desc"),
        startAfter(docSnapshot[docSnapshot.length - 1]),
        limit(10)
      );

      const documentSnapshots = await getDocs(next);

      let docData = [];

      documentSnapshots.forEach((doc) => {
        docData.push({
          id: doc.id,
          ...doc.data(),
        });
      });
      setCreatorData((creatorData) => [...creatorData, ...docData]);
      setDocSnapshot(documentSnapshots.docs);
    }
  };

  const copyLink = (link) => {
    navigator.clipboard.writeText(link);
    message.success("Link Copied to Clipboard");
  };

  useEffect(() => {
    fetchCreatorData();
  }, []);

  return (
    <div>
      <Modal
        open={modalProps.visible}
        closable={false}
        onCancel={() => {}}
        onOk={() =>
          setModalProps((modalProps) => ({ ...modalProps, visible: false }))
        }
        okButtonProps={{ disabled: modalProps.input !== "nahipata" }}
        title="Confirm your Identity"
        maskStyle={{ opacity: 1, background: "black" }}>
        <Input
          placeholder="Enter validation Code"
          type="password"
          onChange={(e) => {
            return setModalProps((modalProps) => ({
              ...modalProps,
              input: e.target.value || "",
            }));
          }}
          value={modalProps.input}
        />
      </Modal>
      <div style={{ margin: "150px" }}>
        <Row justify={"space-between"}>
          <h2>Creator List</h2>
          <Button type="primary" onClick={() => setAddCreatorVisible(true)}>
            Add Creator
          </Button>
        </Row>
        <Row
          style={{
            background: " #f5f5f5",
            borderRadius: "10px",
            boxShadow:
              "inset 1px 0 0 #d9d9d9, inset -1px 0 0 #d9d9d9, inset 0 1px 0 #d9d9d9, inset 0 -1px 0 #d9d9d9",
            padding: " 16px 10px 16px 44px",
          }}>
          <Col span={4}>Name</Col>
          <Col span={4}>Social Media</Col>
          <Col span={2}>Service Fee</Col>
          <Col span={4}> Sent On</Col>
          <Col span={4}>Signed on</Col>
          <Col span={4}>Status</Col>
          <Col span={2}>Actions</Col>
        </Row>

        {creatorData?.length > 0 ? (
          <InfiniteScroll
            loadMore={() => {
              loadMore();
            }}
            id="general"
            hasMore={docSnapshot[docSnapshot.length - 1] !== undefined}
            loader={
              <Row justify="center">
                <Spin />
              </Row>
            }
            style={{ height: "50vh", overflowY: "scroll" }}>
            {creatorData.length === 0 && (
              <Empty description="No Notifications" />
            )}
            {creatorData?.map((item) => {
              return (
                <>
                  <Row
                    style={{
                      padding: " 16px 10px 16px 44px",
                    }}>
                    <Col span={4}>{item.influencerName}</Col>
                    <Col span={4}>
                      {item.socialMediaHandle
                        .map((item) => item.platform)
                        .join(", ")}
                    </Col>
                    <Col span={2}>{item.serviceFee}</Col>
                    <Col span={4}>
                      {format(new Date(item.createdOn), "d LLL, yyyy")}
                    </Col>
                    <Col span={4}>
                      {item?.signedOn
                        ? format(new Date(item.signedOn), "d LLL, yyyy")
                        : "-"}
                    </Col>
                    <Col span={4}>
                      <Tag color={item?.isSubmitted ? "success" : "error"}>
                        {item?.isSubmitted ? "Signed" : "Not-Signed"}
                      </Tag>
                    </Col>
                    <Col span={2}>
                      <Button
                        type="primary"
                        onClick={() => navigate(`/${item.id}`)}>
                        Preview
                      </Button>
                      <Button
                        style={{ marginTop: "10px" }}
                        onClick={() =>
                          copyLink(window.location.href + item.id)
                        }>
                        Copy Link
                      </Button>
                      <Button
                        style={{ marginTop: "10px" }}
                        type="primary"
                        danger
                        onClick={() =>
                          deleteDoc(
                            doc(firebaseDb, "agreements", item.id)
                          ).then(() =>
                            message.success("Agreement Deleted Successfully")
                          )
                        }>
                        Delete
                      </Button>
                    </Col>
                  </Row>
                  <Divider style={{ margin: "5px 0" }} />
                </>
              );
            })}
          </InfiniteScroll>
        ) : (
          <Empty />
        )}

        <AddCreator
          visible={addCreatorVisible}
          handleSubmit={() => {
            setAddCreatorVisible(false);
          }}
          handleClose={() => {
            setAddCreatorVisible(false);
          }}
        />
      </div>
    </div>
  );
};

export default Home;
