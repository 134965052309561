export const getDesc = (type) => {
  switch (type) {
    case "Term":
      return "Well, well, well, looks like we have a time-limited agreement here. It's going to kick off on a specific date and hang around for three months. But wait, there's more! If both parties are feeling the love after that time, they can opt to extend the agreement for another three months. Of course, they'll need to sit down and hash out the nitty-gritty before the initial three months expire. Let the negotiations begin!";
    case "Rights":
      return "Hold onto your hats folks, because this company is about to give their creator a fancy-pants email ending in @x.cloutflow.com. But that's not all - the company will also be the go-to for all business deals related to the creator's social media accounts. However, if the creator is feeling extra ambitious and wants to take on a deal solo, they'll need to get the green light from the company first. Let the business games begin!";
    case "Warranty":
      return "Listen up folks, because both parties are making some pretty hefty promises in this contract. First off, they swear on their mama's grave that they're allowed to agree to this bad boy without stepping on any other toes. As for the creator, they're making some bold claims too. They're saying all their social media followers are the real deal and that they didn't pull any shenanigans to boost their numbers. Oh, and they're also agreeing to follow all the rules laid out by social media platforms, advertising guidelines, and even the government's laws. Let's hope everyone keeps their fingers crossed and sticks to their word!";
    case "Breach":
      return "Hold onto your hats folks, because breaking the rules in this agreement is no laughing matter. If our creator decides to ditch their obligations, fib about something crucial, intentionally harm the company, or fails to keep their promise to protect the company from being sued, we're calling it a Material Breach or an Event of Default. But don't sweat it just yet - if the creator flubs up in a fixable way, we'll give them a gentle nudge and 72 hours to set things right. Let's hope our creator can keep their nose clean!";
    case "INDEMNIFICATION":
      return "Hold onto your hats folks, because this creator has some serious responsibility on their hands. They've gotta play watchdog and protect the company and all its hard working employees from harm. That means if they go and fib, break the agreement, or cause any major hiccups, they're on the hook for any legal trouble or financial losses that follow. But wait, there's more! This promise doesn't stop the company from having other ways to protect themselves. Let's hope our creator can keep their nose clean and out of trouble!";

    default:
      return "";
  }
};
